<template>
<b-row>
  <div class="col-sm-4 nopadding">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link" id="home-tab" data-toggle="tab" v-on:click="activetab=1" v-bind:class="[ activetab === 1 ? 'show active' : '' ]"
        role="tab" aria-controls="home" aria-selected="true"><i class="fa fa-file-text-o" aria-hidden="true"></i> {{$t('common.client_list')}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="profile-tab" data-toggle="tab" v-on:click="activetab=2" v-bind:class="[ activetab === 2 ? 'show active' : '' ]"
        role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-list" aria-hidden="true"></i> {{$t('common.order_list')}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="profile-tab" data-toggle="tab" v-on:click="activetab=3" v-bind:class="[ activetab === 3 ? 'show active' : '' ]"
        role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-list" aria-hidden="true"></i> {{$t('common.delivery_list')}}</a>
      </li>
    </ul>
    <div class="tab-content" id="tab_booking_pawn">
      <div class="tab-pane fade" v-bind:class="[ activetab === 1 ? 'show active' : '' ]" id="tab_pane_booking_pawn" role="tabpanel" aria-labelledby="booking-tab">
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <b-form-input type="text" class="form-control" :placeholder="$t('common.search_text')" size="sm"
            v-model="filter_client" maxlenght="50" @keyup.enter="apply_filter_client"></b-form-input>
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter_client"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <div class="row div-list-detail">
          <div class="col-md-6 col-xs-3" v-for="itm in filteredAndSortedData_client" :key="itm.id">
              <b-card class="booking-detail"
                @click="book_new_client(itm)"
                :border-variant="itm.status>0?'success':'success'">
                <div class="d-flex justify-content-between align-items-center" slot="header">
                  <div class="bg-success">
                    <i>{{itm.name}}</i>
                  </div>
                  <div class="bg-success" v-if="itm.status>0">
                    <i>{{getOrderStatus(itm.status)}}</i>
                  </div>
                  <div class="bg-success" v-else>
                    <i>{{$t('common.table_empty')}}</i>
                  </div>
                </div>
                <div>
                  <label class="row">{{$t('common.project')}}: {{itm.name}}</label>
                  <label class="row">{{$t('common.owner')}}: {{itm.owner}}</label>
                  <label class="row">{{$t('common.phone')}}: {{itm.phone}}</label>
                  <label class="row">{{$t('common.address')}}: {{itm.address}}</label>
                </div>
              </b-card>
          </div>
        </div>
        <div class="row" v-show="false">
          <div class="col">
            <pager class="pull-right" :frame="8"
                  :pageCount="page_count_client"
                  :page="page_client"
                  @change="change_page_client">
            </pager>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" v-bind:class="[ activetab === 2 ? 'show active' : '' ]" id="tab_pane_order_pawn" role="tabpanel" aria-labelledby="order-tab">
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <b-form-input type="text" class="form-control" :placeholder="$t('common.search_text')" size="sm"
            v-model="filter_order" maxlenght="50" @keyup.enter="apply_filter_order"></b-form-input>
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter_order"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData_order" :fields="fields" :current-page="page_order"
        :per-page="0">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="company" slot-scope="data">
            <label v-if="data.item.company">{{data.item.company.name}}</label>
            <label v-else></label>
          </template>
          <template slot="object" slot-scope="data">
            <label v-if="data.item.object">{{data.item.object.name}}</label>
            <label v-else></label>
          </template>
          <template slot="client" slot-scope="data">
            <label v-if="data.item.client">{{data.item.client.name}}</label>
            <label v-else></label>
          </template>
          <template slot="total" slot-scope="data">
            <label v-if="data.item.total>0">{{formatPrice(data.item.total)}}</label>
            <label v-else>0</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getOrderStatus(data.item.status)}}</b-badge>
          </template>

          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
           <template slot="bill" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="primary" @click="delivery_bill_pdf(data.item.id,57)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill 57</b-button>
              <b-button variant="primary" @click="delivery_bill_pdf(data.item.id,80)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill 80</b-button>
              <b-button variant="primary" @click="delivery_bill_pdf(data.item.id,5)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill A5</b-button>
              <b-button variant="primary" @click="delivery_bill_pdf(data.item.id,4)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill A4</b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="row">
          <b-col>
            <pager class="pull-right" :frame="8"
                  :pageCount="page_count_order"
                  :page="page_order"
                  @change="change_page_order">
            </pager>
          </b-col>
        </div>
      </div>
      <div class="tab-pane fade" v-bind:class="[ activetab === 3 ? 'show active' : '' ]" id="tab_pane_delivery_pawn" role="tabpanel" aria-labelledby="delivery-tab">
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <b-form-input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" size="sm" @keyup.enter="apply_filter"></b-form-input>
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="company" slot-scope="data">
            <label v-if="data.item.company">{{data.item.company.name}}</label>
            <label v-else></label>
          </template>
          <template slot="object" slot-scope="data">
            <label v-if="data.item.object">{{data.item.object.name}}</label>
            <label v-else></label>
          </template>
          <template slot="client" slot-scope="data">
            <label v-if="data.item.client">{{data.item.client.name}}</label>
            <label v-else></label>
          </template>
          <template slot="total" slot-scope="data">
            <label v-if="data.item.total>0">{{formatPrice(data.item.total)}}</label>
            <label v-else>0</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getDeliveryStatus(data.item.status)}}</b-badge>
          </template>

          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
           <template slot="bill" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="primary" @click="delivery_bill_pdf(data.item.id,57)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill 57</b-button>
              <b-button variant="primary" @click="delivery_bill_pdf(data.item.id,80)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill 80</b-button>
              <b-button variant="primary" @click="delivery_bill_pdf(data.item.id,5)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill A5</b-button>
              <b-button variant="primary" @click="delivery_bill_pdf(data.item.id,4)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill A4</b-button>
            </b-button-group>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)">
                <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="row" v-show="false">
          <b-col>
            <pager class="pull-right" :frame="8"
                  :pageCount="page_count"
                  :page="page"
                  @change="change_page">
            </pager>
          </b-col>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-8 nopadding">
    <div class="col">
      <div class="row">
        <div class="col bg-info">
          <div class="row mt-1">
            <div class="col-6 h5">
              <i class="fa fa-user" aria-hidden="true"></i>  {{$t('common.project')}} <label class="text-danger" v-if="delivery.name!=null">[{{delivery.name}}]</label>
            </div>
            <div class="col-6">
              <strong class="pull-right"><i class="fa fa-money 2x"
              aria-hidden="true"></i> {{formatPrice(total_recal)}} {{$t('common.money_icon')}}</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="row bg-secondary">
        <div class="col">
          <div class="row">
            <div class="col-md-4">
              <b-form-group>
                <label for="ddl_client_list">{{$t('common.client')}}</label>
                <div v-if="$v.delivery.client_id.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.delivery.client_id.required">{{$t('common.client_required')}}</div>
                </div>

                <b-form-select :options="ls_client_ddl"
                  id="ddl_client_list" v-on:input="$v.delivery.client_id.$touch"
                  @change="onClientChange(delivery.client_id)"
                  v-model.trim="delivery.client_id"
                  :placeholder="$t('common.select_client')">
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <label for="txt_delivery_staff">{{$t('common.delivery')}}</label>
              <div v-if="$v.delivery.staff_id.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.delivery.staff_id.required">{{$t('common.delivery_required')}}</div>
              </div>
              <b-form-input type="text" id="txt_delivery_staff"
                v-model.trim="delivery.staff_id" maxlength="100"
                v-on:input="$v.delivery.staff_id.$touch" size="sm"
                :placeholder="$t('common.placeholder_delivery')"></b-form-input>
            </div>
            <div class="col-md-4">
              <b-form-group>
                <label for="input_delivery_date">{{$t('common.delivery_day')}}</label>
                <div v-if="$v.delivery.delivery_date.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.delivery.delivery_date.required">{{$t('common.delivery_day_required')}}</div>
                </div>
                <date-picker v-model="delivery.delivery_date" :config="options_datetime"
                 class="date-picker-height"></date-picker>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label for="ddl_client_list">{{$t('common.select_client')}}</label>
              <div v-if="$v.delivery.client_id.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.delivery.client_id.required">{{$t('common.client_required')}}</div>
              </div>
              <b-form-select id="ddl_client_list"
              v-model="delivery.client_id" size="sm">
                  <option value="null" disabled>{{$t('common.select_client')}}</option>
                  <option v-for="d in ls_client_delivery_ddl" :value="d.id"
                      v-bind:key="d.id">{{d.name}}</option>
              </b-form-select>
            </div>
            <div class="col-md-4">
              <label for="txt_discount">{{$t('common.discount')}}</label>
              <b-form-group>
                <money v-model.trim="delivery.discount" v-bind="money_discount" class="form-control text-success"
                  id="'txt_discount" maxLength="4"></money>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <label for="txt_address">{{$t('common.address')}}</label>
              <div v-if="$v.delivery.address.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.delivery.address.required">{{$t('common.address_required')}}</div>
              </div>
              <b-form-input type="text" id="txt_address"
                v-model.trim="delivery.address" maxlength="200"
                v-on:input="$v.delivery.address.$touch" size="sm"
                :placeholder="$t('common.placeholder_address')"></b-form-input>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <fieldset class="scheduler-border col-md-12">
              <legend class="scheduler-border h4">{{$t('common.product_list')}}</legend>
              <div class="div-scroll row">
                <div class="col">
                  <div class="row" v-for="(itm, k) in $v.delivery.delivery_detail.$each.$iter"
                    :key="k" :class="['', (itm.status.$model>-1?(k%2==0?'bg-light':''):'bg-secondary')]">
                    <div class="col">
                      <div class="row">
                        <div class="form-group col-md-3 form-fixer">
                          <label :for="'txt_product_'+k">{{$t('common.product')}}</label>
                          <div v-if="$v.delivery.delivery_detail.$error" class="pull-right">
                            <div class="text-danger" v-if="!itm.product_id.required">{{$t('common.product_required')}}</div>
                            <div class="text-danger" v-else-if="!itm.product_id.isUnique">{{$t('common.product_existing')}}</div>
                          </div>
                          <b-form-select v-model.trim="itm.product_id.$model"
                            :id="'ddl_product_'+k" v-on:input="itm.product_id.$touch"
                            @change="onProductChange(k,itm.$model)" size="sm">
                            <option value="null" disabled>{{$t('common.select_product')}}</option>
                            <option v-for="c in ls_product_ddl" :value="c.id"
                            v-bind:key="c.id">{{c.name}} <i v-if="c.cocon">[{{$t("common.product_import_by_car")}}]</i></option>
                          </b-form-select>
                        </div>
                        <div class="form-group col-md-2 form-fixer">
                          <label :for="'ddl_unit_'+k">{{$t('common.unit')}}</label>
                          <div v-if="$v.delivery.delivery_detail.$error" class="pull-right">
                            <div class="text-danger" v-if="!itm.unit_id.required">{{$t('common.unit_required')}}</div>
                          </div>
                          <b-form-select :id="'ddl_unit_'+k" :disabled="itm.product_id.$model==null"
                          v-model="itm.unit_id.$model" @change="onUnitChange(k,itm.unit_id.$model)" size="sm">
                              <option value="null" disabled>{{$t('common.select_unit')}}</option>
                              <option v-for="d in delivery.delivery_detail[k].ls_unit_ddl" :value="d.id"
                                  v-bind:key="d.id">{{JSON.parse(d.name)[$i18n.locale]}}</option>
                          </b-form-select>
                        </div>
                        <div class="form-group col-md-2 form-fixer">
                          <label :for="'txt_price_'+k">{{$t('common.price')}}</label>
                          <div v-if="$v.delivery.delivery_detail.$error" class="pull-right">
                            <div class="text-danger" v-if="!itm.price.required">{{$t('common.price_required')}}</div>
                          </div>
                          <money v-model.trim="itm.price.$model" v-bind="money" class="form-control"
                          :name="'txt_price_'+k" :id="'txt_price_'+k" :disabled="itm.product_id.$model==null ||itm.unit_id.$model==null"
                          @change.native="onPriceChange(k,itm.price.$model)"
                          maxLength="15"></money>
                        </div>
                        <div class="form-group col-md-2 form-fixer">
                          <label :for="'txt_quantity_'+k">{{$t('common.quantity')}}</label>
                          <div v-if="$v.delivery.delivery_detail.$error" class="pull-right">
                            <div class="text-danger" v-if="!itm.quantity.required">{{$t('common.quantity_required')}}</div>
                            <div class="text-danger" v-else-if="!itm.quantity.numeric">{{$t('common.quantity_number_only')}}</div>
                          </div>
                          <b-form-input type="number" @change="onQualityChange(k,itm.quantity.$model)"
                            v-model.trim="itm.quantity.$model" class="form-control" size="sm"
                            :name="'txt_quantity_'+k" :id="'txt_quantity_'+k" :disabled="itm.product_id.$model==null ||itm.unit_id.$model==null"
                            maxLength="15" :placeholder="$t('common.placeholder_quantity')"></b-form-input>
                        </div>
                        <div class="form-group col-md-2 form-fixer">
                          <label :for="'txt_total_'+k">{{$t('common.total')}}</label>
                          <div v-if="$v.delivery.delivery_detail.$error" class="pull-right">
                            <div class="text-danger" v-if="!itm.total.required">{{$t('common.total_required')}}</div>
                            <div class="text-danger" v-else-if="!itm.total.numeric">{{$t('common.total_number_only')}}</div>
                          </div>
                          <money v-model.trim="itm.total.$model" v-bind="money"  class="form-control"
                          :name="'txt_total_'+k" :id="'txt_total_'+k" :disabled="itm.product_id.$model==null ||itm.unit_id.$model==null"
                          maxLength="15"></money>
                        </div>
                        <div class="col-md-1 pull-right text-white" v-if="itm.status.$model>-1">
                          <div v-if="delivery.id==null">
                            <a class="btn btn-danger mr-1 btn-sm" v-if="delivery.delivery_detail.length>0"
                              @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                            <a class="btn btn-success btn-sm" @click="addNewRow(delivery.order_id)"><i class="fa fa-plus" aria-hidden="true"></i></a>
                          </div>
                          <div v-else>
                            <a class="btn btn-danger mr-1 btn-sm"
                            @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                            <a class="btn btn-success btn-sm" @click="addNewRow(delivery.order_id)"><i class="fa fa-plus" aria-hidden="true"></i></a>
                          </div>
                        </div>
                        <div class="col-md-1 pull-right text-white" v-else>
                          <a class="btn btn-success mr-1 btn-sm" v-if="delivery.delivery_detail.length>0"
                            @click="itm.status.$model=0"><i class="fa fa-refresh" aria-hidden="true"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <b-button variant="outline-success mr-1" size="sm" @click.prevent="clear();addNewRow(delivery.order_id);refresh()"><span class="icon is-small">
              <i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</span></b-button>
            <b-button variant="outline-primary" size="sm" @click.prevent="save_delivery()"><span class="icon is-small">
              <i class="fa fa-check"></i> {{$t('common.button.save')}} </span></b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <b-modal id="bill_viewModal" :title="$t('common.view_bill')" size="lg" header-bg-variant="info" no-body ok-only>
    <div id="pawn_delivery_bill_pdf" style="height:450px;"></div>
  </b-modal>
</b-row>
</template>
<style scoped>
  div.form-fixer {
      padding-left: 1px !important;
      padding-right: 1px !important;
      /* font-size: 19px; */
  }
  label{
    margin-bottom: 0rem;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }
  .div-scroll {
    max-height:500px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width:auto;
    padding:0 10px;
    border-bottom:none;
  }
  .row > div.div-list-detail[class*='col-'] {
    display: flex;
    flex:1 0 auto;
  }
  div.card.booking-detail > div.card-body{
    padding-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  div.card.booking-detail > div.card-header{
    padding: 0px !important;
    border-bottom:0px !important;
  }
  div.card.booking-detail > div.card-footer{
    padding: 0px !important;
    border-top:0px !important;
  }
</style>
<script>
  import pdfobj from 'pdfobject'
  import { validationMixin } from 'vuelidate'
  import { required,requiredIf, minLength,maxLength,email,url,numeric } from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'
  import datePicker from 'vue-bootstrap-datetimepicker'
  import moment from 'moment'
  import Pager from '../Pager'
  import { ModelSelect } from 'vue-search-select'
  import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
  import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
  import { Role } from '@/helpers/role.js'
  import { uuid } from 'vue-uuid'
  import { Business } from '../../helpers/business'
  const greaterThanZero = (value) => value > 0
  export default {
    components: { datePicker,ModelSelect,Pager,VueBootstrapTypeahead},
    mixins: [validationMixin],
    props: {
      hover: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        activetab: 1,
        processing:false,
        money_discount: {
          decimal: ',',
          thousands: '.',
          prefix: '% ',
          precision: 0,
          masked: false
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '$ ',
          precision: 0,
          masked: false
        },
        delivery: {
          id:null,
          name:null,
          address:null,
          client_id:null,
          order_id:null,
          object_id:null,
          delivery_detail:[],
          user_create_id:null,
          user_update_id:null,
          total:0,
          delivery_date:moment().format('DD-MM-YYYY HH:mm:ss'),
          staff_id:null,
          company_id:null,
          business:null,
          branch_id:null,
          in_time:moment().format('HH:mm'),
          out_time:null,
          discount:0,
          code:moment().valueOf().toString()
        },
        options: {
          format: 'DD-MM-YYYY',
          useCurrent: true,
          showClear: true,
          showClose: true
        },
        options_datetime: {
          format: 'DD-MM-YYYY HH:mm:ss',
          useCurrent: true,
          showClear: true,
          showClose: true
        },
        options1: {
          format: 'DD-MM-YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true
			  }
      }
    },
    validations: {
      delivery: {
        delivery_date:{
          required
        },
        client_id:{
          required
        },
        address:{
          required
        },
        object_id:{
          required
        },
        staff_id:{
          required
        },
        delivery_detail: {
          $each: {
            product_id:{
              required: requiredIf(function () {
                return this.delivery.delivery_detail.length>0
              }),
              async isUnique(value,itm) {
                debugger
                if(this.delivery.delivery_detail.length<=0) return Boolean(true)
                let arr=this.delivery.delivery_detail.filter(x => (x.unit_id == itm.unit_id && x.product_id==itm.product_id))
                return Boolean(arr.length<=1)
              }
            },
            unit_id:{
              required: requiredIf(function (itm) {
                return this.delivery.delivery_detail.length>0 && itm.product_id!=null
              })
            },
            quantity:{
              required: requiredIf(function () {
                return this.delivery.delivery_detail.length>0
              }),
              numeric
            },
            total:{
              required: requiredIf(function () {
                return this.delivery.delivery_detail.length>0
              }),
              numeric
            },
            price:{
              required: requiredIf(function () {
                return this.delivery.delivery_detail.length>0
              }),
              numeric
            },
            status:{},
            ls_unit_ddl:[]
          }
        }
      }
    },
    computed: {
      ...mapGetters('st_order', ['ls_order','per_page_order','filter_order','page_count_order','page_order']),
      ...mapGetters('st_delivery', ['ls_delivery','per_page','filter','page_count','page']),
      ...mapGetters('st_client', ['ls_client','per_page_client','filter_client','page_count_client','page_client']),
      fields(){
        let fields=[]

        let user=this.$store.state.st_authentication.user
        if(user.role==Role.Super_Admin){
          fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
        }else if(user.role==Role.Admin){
          fields.push({key: 'branch',label:this.$t('common.branch'),sortable: true, variant:'info'})
        }

        fields.push({key: 'object',label:this.$t('common.project'),sortable: true})
        fields.push({key: 'client',label:this.$t('common.client'),sortable: true})
        fields.push({key: 'total',label:this.$t('common.total'),sortable: true})
        fields.push({key: 'status',label:this.$t('common.status'),sortable: true})
        fields.push({key: 'bill',label:this.$t('common.bill'),sortable: false})
        fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})

        if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
          fields.push({key: 'action',label:'',sortable: false})
        }

        return fields
      },
      total_recal(){
        let temp=_.sumBy(this.delivery.delivery_detail.filter(x=>x.status>=0),"total")
        return parseInt(temp-((temp)*(this.delivery.discount/100)))
      },
      ls_product_ddl(){
        let ls= this.$store.state.st_product.ls_product_ddl
        return ls
      },
      is_super () {
        let user= this.$store.state.st_authentication.user
        return user.role==Role.Super_Admin
      },
      business () {
        let user= this.$store.state.st_authentication.user
        return user.company.business
      },
      user_login () {
        return this.$store.state.st_authentication.user
      },
      per_page_client:{
        get: function(){
          return this.$store.state.st_client.per_page_client
        },
        set: function(page){
          this.$store.commit('st_client/set_per_page',page)
        }
      },
      filter:{
        get: function(){
          return this.$store.state.st_delivery.filter
        },
        set: function(text){
          this.$store.commit('st_delivery/set_filter',text)
        }
      },
      filter_client:{
        get: function(){
          return this.$store.state.st_client.filter_client
        },
        set: function(text){
          this.$store.commit('st_client/set_filter',text)
        }
      },
      ls_unit_ddl(){
        return this.$store.state.st_unit.ls_unit_ddl
      },

      ls_client_delivery_ddl(){
        return this.$store.state.st_client.ls_client_delivery_ddl
      },
      ls_car_ddl(){
        return this.$store.state.st_car.ls_car_ddl
      },

      filteredAndSortedData:{
        get: function(){
          let vm=this
          let result = vm.$store.state.st_delivery.ls_delivery
          if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
          }

          return result
        }
      },
      ls_client_ddl(){
        return this.$store.state.st_client.ls_client.filter(x=>x.status==1).map((obj)=>{
              return {
                  'id':obj.id,
                  'name':obj.name,
                  'status':obj.status
              }}
            )
      },
      filteredAndSortedData_client:{
        get: function(){
          let vm=this
          let result = vm.$store.state.st_client.ls_client.filter(x=>x.status>0)
          debugger
          if (vm.filter_client) {
            result = result.filter(item =>vm.searchLike_client(item))
          }

          return result
        }
      },
      filteredAndSortedData_order:{
        get: function(){
          let vm=this
          let result = vm.$store.state.st_order.ls_order
          debugger
          if (vm.filter_order) {
            result = result.filter(item =>vm.searchLike_order(item))
          }

          return result
        }
      }
    },
    methods: {
      ...mapActions('st_order', ['get_ls_order','apply_filter_order','change_page_order']),
      ...mapActions('st_delivery', ['get_ls_delivery','apply_filter','change_page']),
      ...mapActions('st_client', ['get_ls_client','apply_filter_client','change_page_client']),
      ...mapActions('st_unit', ['get_ls_unit_ddl']),
      ...mapActions('st_product', ['get_ls_product_ddl']),
      ...mapActions('st_client', ['get_ls_client_delivery_ddl']),
      ...mapActions('st_car', ['get_ls_car_ddl']),

      day_diff(str_date_time){
        let now=moment()
        let another_date=moment(str_date_time, 'YYYY-MM-DD hh:mm:ss')
        let diff=now.diff(another_date, 'minutes')
        if(diff<60){
          return diff +" "+ this.$t('common.minutes') +" "
        }else if(diff<(60*24)){
          return now.diff(another_date, 'hours') +" "+ this.$t('common.hours')+" "
        }else if(diff<(60*24*7)){
          return now.diff(another_date, 'days') +" "+ this.$t('common.days')+" "
        }else{
          return now.diff(another_date, 'weeks') +" "+ this.$t('common.weeks')+" "
        }
      },
      rowDeleted(item, type){
        if (!item || type !== 'row') return
        if (item.status === -1) return 'table-danger'
      },
      async book_new_client(obj){
        if(!obj) return
        this.clear()
        let order=this.filteredAndSortedData_order.find(x=>x.object_id==obj.id&& x.company_id==obj.company_id&&x.branch_id==obj.branch_id&&x.status==1)
        this.delivery.order_id=order.id
        this.delivery.name=obj.name
        this.delivery.address=obj.address
        this.delivery.object_id=obj.id
        this.addNewRow(order.id)
        this.refresh()
      },
      object_bill_pdf(obj,type){
        let app=this
        debugger
        let delivery=this.filteredAndSortedData.find(x=>x.object_id==obj.id&& x.company_id==obj.company_id&&x.branch_id==obj.branch_id&&x.status==1)
        if(!delivery) return
        app.delivery_bill_pdf(delivery.id,type)
      },
      delivery_bill_pdf(id,type){
        let app=this
        var loader = app.$loading.show()
        app.$store.dispatch('st_delivery/bill_pdf',{id,type,business:app.business,lang:app.$i18n.locale}).then((response) => {
          if(response && response.data){
            let blob = new Blob([response.data], { type: 'application/pdf' })
            pdfobj.embed(window.URL.createObjectURL(blob), "#pawn_delivery_bill_pdf")
          }

          loader.hide()
        })
      },
      onProjectChange(client_id){
        let obj=this.ls_client_ddl.find(x=>x.id==client_id)
        if(!obj) return

        this.delivery.client_id=obj.id
        this.delivery.name=obj.name
        this.delivery.address=obj.address
      },
      onProductChange(k,itm_detail){
        if(itm_detail==null) return
        debugger
        let product=this.ls_product_ddl.find(x => x.id === itm_detail.product_id)
        k=parseInt(k)
        if(product){
          var filteredArray = this.ls_unit_ddl.filter(function(array_el){
            return product.product_units.filter(function(anotherOne_el){
              if(anotherOne_el.unit_id == array_el.id){
                array_el.buy_price= anotherOne_el.buy_price
                array_el.sell_price= anotherOne_el.sell_price
              }
              return anotherOne_el.unit_id == array_el.id
            }).length > 0
          })

          if(filteredArray.length<=0){
            filteredArray=this.ls_product_ddl
          }

          this.delivery.delivery_detail[k].ls_unit_ddl=filteredArray
          this.delivery.delivery_detail[k].price=product.sell_price
        }else{
          this.delivery.delivery_detail[k].price=0
        }

        this.delivery.delivery_detail[k].quantity=0
        this.delivery.delivery_detail[k].total=0
        this.delivery.delivery_detail[k].unit_id=null
      },
      onUnitChange(k,unit_id){
        if(unit_id==null) return
        debugger
        k=parseInt(k)
        let unit=this.delivery.delivery_detail[k].ls_unit_ddl.find(x=>x.id==unit_id)
        this.delivery.delivery_detail[k].quantity=1
        if(unit){
          this.delivery.delivery_detail[k].total=this.delivery.delivery_detail[k].price=unit.sell_price
        }
      },
      onPriceChange(k,event){
        if(event==null) return
        k=parseInt(k)
        if(this.delivery&&this.delivery.delivery_detail[k].quantity>0){
          this.delivery.delivery_detail[k].total=parseInt(this.delivery.delivery_detail[k].quantity) * parseInt(this.delivery.delivery_detail[k].price)
        }
      },
      onQualityChange(k,event){
        if(event==null) return
        k=parseInt(k)
        if(this.delivery.delivery_detail[k].quantity>0){
          this.delivery.delivery_detail[k].total=parseInt(this.delivery.delivery_detail[k].quantity) * parseInt(this.delivery.delivery_detail[k].price)
        }else{
          this.delivery.delivery_detail[k].total=0
        }
      },
      addNewRow(order_id) {
        this.delivery.delivery_detail.push({
          id:null,
          product_id:null,
          price:0,
          status:0,
          total:0,
          ls_unit_ddl:[],
          unit_id:null,
          order_id:order_id,
          quantity:0,
          user_create_id:this.user_login.id,
          user_update_id:null,
          company_id:this.user_login.company_id,
          branch_id:this.user_login.branch_id,
          business:this.user_login.company.business
        })
      },
      removeRow(k){
        if(k==null || parseInt(k)<0) return
        let itm=this.delivery.delivery_detail[k]
        if(itm && itm.id){
          this.confirmRemoveDetail(k)
        }else{
          this.delivery.delivery_detail.splice(k, 1)
        }
      },
      confirmRemoveDetail(k) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      }).then(value => {
          if(value!=true) return
          this.delivery.delivery_detail[k].status=-1
        })
        .catch(err => {
          this.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
      getRowCount (items) {
        return items.length
      },

      rowClicked (item) {
        if(item==null || item.status<0) return
        this.delivery=item
        debugger
        for (var i = 0; i < item.delivery_detail.length; i++) {
          let element = item.delivery_detail[i]
          let product=this.ls_product_ddl.find(x=>x.id==element.product_id)
          if(product){
            var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
              return product.product_units.filter(function(anotherOne_el){
                if(anotherOne_el.unit_id == array_el.id){
                  array_el.buy_price= anotherOne_el.buy_price
                  array_el.sell_price= anotherOne_el.sell_price
                }
                return anotherOne_el.unit_id == array_el.id
              }).length > 0
            })

            if(filteredArray.length<=0){
              filteredArray=this.ls_product_ddl
            }

            element.ls_unit_ddl=filteredArray
          }
        }
      },
      refresh(){
        this.$v.$reset()
      },
      clear() {
        debugger
        this.delivery={id:null,branch_id:null,delivery_detail:[],order_id:null,
        address:null,in_time:moment().format("HH:mm"),out_time:null,staff_id:null,
        company_id:null,object_id:null,user_create_id:this.user_login.id,client_id:null,
        discount:this.user_login.company.discount,delivery_date:moment().format('DD-MM-YYYY HH:mm:ss'),
        user_update_id:null,business:null,code:moment().valueOf().toString(),total:0}
      },
      _validations () {
        return new Promise(resolve => {
          if (this.$v.$error || !this.$v.$pending) {
              return resolve()
          }
          let poll = setInterval(() => {
          if (!this.$v.$pending) {
              clearInterval(poll)
              resolve()
          }
          }, 200)
        })
      },
      async isValidDelivery () {
        this.$v.delivery.$reset()
        this.$v.delivery.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.delivery.$error)
      },

      async save_delivery() {
        var app = this
        debugger
        const isValid = await this.isValidDelivery()
        if (!isValid) return
        if (app.processing === true) return

        app.processing = true

        if(app.delivery.id){
          app.delivery.user_update_id=app.user_login.id
          app.delivery.total=app.total_recal
          app.$store.dispatch('st_delivery/update',app.delivery)
          .then(function (resp) {
            app.get_ls_order()
            app.get_ls_delivery()
            app.get_ls_client()
            app.clear()
            app.addNewRow(app.delivery.order_id)
            app.refresh()
            app.$toastr.success(app.$t('common.updated_success'),app.$t("common.message"))
            app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
            app.processing = false
          })
        }else{
          app.delivery.user_create_id=app.user_login.id
          app.delivery.business=app.user_login.company.business
          app.delivery.company_id=app.user_login.company_id
          app.delivery.branch_id=app.user_login.branch_id
          app.delivery.total=app.total_recal
          app.delivery.out_time=moment().format("HH:mm")
          app.$store.dispatch('st_delivery/create',app.delivery)
          .then(function (resp) {
            if(resp.status==false){
              app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
              app.processing = false
              return
            }
            app.get_ls_order()
            app.get_ls_delivery()
            app.get_ls_client()
            app.clear()
            app.addNewRow(app.delivery.order_id)
            app.refresh()
            app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
            app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
            app.processing = false
          })
        }
      },

      async recoverObj(obj){
        let app=this
        if(obj.status>=0) return
        let co=await app.$store.dispatch('st_delivery/recover_obj',obj.id)
        if(co && co.data.ok){
          app.get_ls_order()
          app.get_ls_delivery()
          app.get_ls_client()
          app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },

      confirmDelete(obj) {
        let app=this
        if(obj.status<0) return
        if(obj.status==0) {
          app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.delivery")}),app.$t("common.message"))
          return
        }
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      }).then(value => {
          if(value!=true) return
          this.deleteObj(obj)
        })
        .catch(err => {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
      async deleteObj(obj) {
        let app=this
        let co=await app.$store.dispatch('st_delivery/delete_obj',obj.id)
        if(co&&co.data.ok){
          app.get_ls_order()
          app.get_ls_delivery()
          app.get_ls_client()
          app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },

      searchLike_client:function(item){
        debugger
        return item.created_at.includes(this.filter_client)
        || item.updated_at&&item.updated_at.includes(this.filter_client)
        || item.branch&&item.branch.name.includes(this.filter_client)
        || item.company.name.includes(this.filter_client)
        || item.name.includes(this.filter_client)
        || item.owner.includes(this.filter_client)
        || item.price.toString().includes(this.filter_client)
        || item.start.includes(this.filter_client)
        || item.end.includes(this.filter_client)
        || item.phone&&item.phone.includes(this.filter_client)
        || item.email&&item.email.includes(this.filter_client)
        || item.address&&item.address.includes(this.filter_client)
        || item.des&&item.des.includes(this.filter_client)
      },

      searchLike:function(item){
        return item.created_at.includes(this.filter)
        || item.updated_at&&item.updated_at.includes(this.filter)
        || item.branch&&item.branch.name.includes(this.filter)
        || item.company.name.includes(this.filter)
        || item.object&&item.object.name.includes(this.filter)
        || item.client&&item.client.name.includes(this.filter)
        || item.total&&item.total.toString().includes(this.filter)
        || item.address&&item.address.includes(this.filter)
        || item.staff&&item.staff.name.includes(this.filter)
        || item.delivery_date&&item.delivery_date.includes(this.filter)
      },
      searchLike_order:function(item){
        return item.created_at.includes(this.filter_order)
        || item.updated_at&&item.updated_at.includes(this.filter_order)
        || item.branch&&item.branch.name.includes(this.filter_order)
        || item.company.name.includes(this.filter_order)
        || item.object&&item.object.name.includes(this.filter_order)
        || item.client&&item.client.name.includes(this.filter_order)
        || item.total&&item.total.toString().includes(this.filter_order)
        || item.address&&item.address.includes(this.filter_order)
      }
    },
    mounted () {
      let vm =this
      vm.per_page_client=1000
      vm.delivery.code=moment().valueOf().toString()
      vm.delivery.discount=vm.user_login.company.discount
      vm.addNewRow(vm.delivery.order_id)
      vm.get_ls_car_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id})
      vm.get_ls_client_delivery_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id})
      vm.get_ls_order()
      vm.get_ls_delivery()
      vm.get_ls_client()
      vm.get_ls_unit_ddl({company_id:vm.user_login.company_id,business:vm.user_login.company.business,lang:vm.$i18n.locale})
    }
  }
</script>
